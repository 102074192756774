import logo from "./logo.svg";
import "./App.css";
import NavBar from "./component/NavBar";
import { useRef, useEffect } from "react";

function App() {
  const section1 = useRef();
  const section2 = useRef();
  const section3 = useRef();
  const section4 = useRef();
  const section5 = useRef();

  const navHeader = [
    {
      headerTitle: "Home",
      headerRef: section1,
      headerID: "section1",
    },
    {
      headerTitle: "Achievements",
      headerRef: section2,
      headerID: "section2",
    },
    {
      headerTitle: "Experience",
      headerRef: section3,
      headerID: "section3",
    },
    {
      headerTitle: "Skills",
      headerRef: section4,
      headerID: "section4",
    },
    {
      headerTitle: "About Me",
      headerRef: section5,
      headerID: "section4",
    },
  ];

  useEffect(() => {
    if (section1.current) {
      const rect = section1.current.getBoundingClientRect();
      const x = rect.left + window.scrollX;
      const y = rect.top + window.scrollY;
      console.log(`X coordinate: ${x}, Y coordinate: ${y}`);
    }
  }, []);

  const activeSection = () => {
    return true;
  };
  return (
    <div className="App">
      <NavBar active={activeSection()} navHeader={navHeader} />
      <section id="home" className="title-section" ref={section1}>
        <div className="component">
          <div className="sub-component">
            <p className="intro-text">Hello World, I'm</p>
            <p className="name-title">Aditya Dutta</p>
            <p className="sub-title">Software Developer</p>
          </div>
          <img className="profile_pic" src="profile_pic.jpg" />
        </div>
      </section>
      <hr />
      <section id="achievements" className="achievements" ref={section2}>
        <h2 className="achievements-title">Awards and Certifications</h2>
        <div class="image-container">
          <div className="image-item">
            <img
              src="aws-certified-developer-associate.png"
              className="aws-img"
              alt="Image 1"
            />
            <p>AWS Certified Developer Associate</p>
          </div>
          <div className="image-item">
            <img src="Insta_Award.png" className="insta-img" alt="Image 2" />
            <p>Infosys Internal Award for Leadership skills</p>
          </div>
          <div className="image-item">
            <img
              src="Code_Love_Hack.png"
              className="google-img"
              alt="Image 3"
            />
            <p style={{ marginBottom: "0" }}>
              Google Cloud Hackathon Winner 2021
            </p>
            <a href="https://lnkd.in/gqKZcC3">https://lnkd.in/gqKZcC3</a>
          </div>
        </div>
      </section>
      <hr />
      <section id="experience" className="experience" ref={section3}>
        <h2 className="experience-title">Experience</h2>
        <div className="exp-container">
          <div className="infy-img-container">
            <img className="infy-img" src="infosys.jpg" alt="infy" />
          </div>
          <div className="infy-text">
            <h3 className="infy-title">
              Infosys{" "}
              <span
                style={{ fontSize: "14px", opacity: "0.7", marginLeft: "4px" }}
              >
                (August, 2021 - Feb, 2024)
              </span>
            </h3>
            <p className="infy-sub-title">Software Developer</p>
            <p className="infy-exp">
              I worked for Infosys for about 2 and a half years as a Software
              Developer. My client was NBN Australia through Infosys. My main
              task was to create and maintain Microservices in Java SpringBoot
              (RESTful APIs). The industry standard microservices had many
              different technologies exposed on which I worked as well such as
              AWS Cloud integration to host and process telemetry data, Python
              project that was used as a rule and template engine to come up
              with data parameters, Redis and Kafka for caching and streaming of
              data. My team of 5, even got the chance to create a whole new
              service which was made from groud zero with no prior code base,
              which had about 200 story points in 4 months. I got an internal
              award for excellent leadership skills, got the highest grade in
              the year end evaluation and got promoted as well.
            </p>
          </div>
        </div>
      </section>
      <hr />
      <section id="skills" className="skills" ref={section4}>
        <h2 className="skills-title">Skills</h2>
        <div className="skills-container">
          <div className="skills-item">
            <img className="skills-img" src="Java_logo.png" />
            <ul>
              <li>
                Java SpringBoot with Maven and Gradle to create RESTFul
                Microservices APIs
              </li>
              <li>Java 8 && Java 11</li>
              <li>Model View Controller Architecture</li>
              <li>Multi Threading and Reactive coding</li>
            </ul>
          </div>
          <div className="skills-item">
            <img className="skills-img" src="JavaScript_Logo.png" />
            <ul>
              <li>Proficient in React Js to create full stack applications</li>
              <li>Node JS & Express JS</li>
              <li>Strong base in TypeScript, Next JS and Redux</li>
            </ul>
          </div>
          <div className="skills-item">
            <img className="skills-img" src="python-logo.png" />
            <ul>
              <li>
                Worked on Pyhton projects which was used to assess system health
              </li>
              <li>Familiar with libraries like numpy and pandas</li>
              <li>Worked using Juptyer Notebook for data science projects</li>
              <li>
                Created simple games in Python like tic-tac-toe and connect4
              </li>
            </ul>
          </div>
        </div>
        <div className="skills-container">
          <div className="skills-item">
            <img className="skills-img" src="db_logo.jpg" />
            <ul>
              <li>
                Well versed with query based databases such as MySQL,
                PostgresSQL
              </li>
              <li>Hands on experience with DynamoDb</li>
              <li>Experienced with document based databases such as MongoDb</li>
              <li>
                Adept at advanced SQL queries with concepts such as inner,
                outer, left, right joins
              </li>
            </ul>
          </div>
          <div className="skills-item">
            <img className="skills-img" src="aws_logo.jpg" />
            <ul>
              <li>Worked on databases using DynamoDb to manage user data</li>
              <li>Used S3 buckets for storing and uploading of files</li>
              <li>
                AWS Lambda to serverlessly run functions when an event is
                triggered
              </li>
              <li>
                Created ElasticSearch indexes for storage and retrieval of
                telemetry data
              </li>
            </ul>
          </div>
          <div className="skills-item">
            <img className="skills-img" src="documentation-vector-icon.jpg" />
            <ul>
              <li>
                Maintained documentation on all the code I wrote on Confluence
              </li>
              <li>Managed User Stories on Jira</li>
              <li>
                Created user tests and uploaded screenshots accoringly on Jira
                for more than 200 tests
              </li>
              <li>
                Hands on experinece with Jenkins for pipelining code building
              </li>
              <li>Adept at MS Word and MS Excel</li>
            </ul>
          </div>
        </div>
      </section>
      <hr />
      <section id="aboutMe" className="about-me" ref={section5}>
        <h2 className="about-me-title">About Me</h2>
        <div className="about-container">
          <div className="about-item">
            <img src="pune.jpg" className="pune-img about-img" />
            <span className="about-item-text">
              My hometown is Pune, it is always voted as one of the most
              liveable city in India. I did my studies at the Bishops Co-Ed
              school a school with more than 150 years of history and one of the
              highest rated in the country. Few things Pune is famous for are
              Shivaji Maharaj's Forts and spicy food such as Miasl Pav.
            </span>
          </div>
          <div className="about-item">
            <img src="tennis.jpg" className="tennis-img about-img" />
            <span className="about-item-text">
              I love playing sports, my favourite being Tennis. I am a die hard
              Rafa Nadal fan and since he is retiring this year that makes me
              very sad. I also play a lot of sports and am quite good at Tennis,
              Table Tennis, Football (European), Cricket and Badminton. I guess
              you can tell by this I spent a lot of my childhood being very
              active.
            </span>
          </div>
        </div>
        <div className="about-container">
          <div className="about-item">
            <img src="melbourne.jpg" className="about-img" />
            <span className="about-item-text">
              My new hometown Melbourne. I guess it's quite fitting that I moved
              from the most liveable city in India to one of the most liveable
              in the world. I love Melbourne except maybe the weather. I studied
              at RMIT University Melbourne from 2018 to 2021 and it was an
              amazing experience to have.
            </span>
          </div>
          <div className="about-item">
            <img src="teamwork.jpg" className="about-img" />
            <span className="about-item-text">
              An important part of my values is teamwork, I believe
              communicating properly is the key to success and we should all
              strive to be more open and understanding. I like my individual
              sports but the satisfaction after winning with your team whether
              at football or video games just hits different and that is what I
              want to bring at my next job as well.
            </span>
          </div>
        </div>
      </section>
      <footer>
        <div>
          <h2>Contact Me</h2>
        </div>
        <div>
          <p>Phone: 0410612224</p>
          <p>
            Email:{" "}
            <a className="linkedin-link" href="mailto:adidutta02@gmail.com">
              adidutta02@gmail.com
            </a>
          </p>
          <p>
            LinkedIn:{" "}
            <a
              className="linkedin-link"
              href="https://www.linkedin.com/in/aditya-dutta-0a0540172"
            >
              https://www.linkedin.com/in/aditya-dutta-0a0540172
            </a>
          </p>
        </div>
        <div>
          <p>Permanent Resident of Australia</p>
          <p>Tarneit, Melbourne</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
