// Navbar.js
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

function NavBar({ navHeader }) {
  const nearestIndex = (
    currentPosition,
    sectionPositionArray,
    startIndex,
    endIndex
  ) => {
    if (startIndex === endIndex) return startIndex;
    else if (startIndex === endIndex - 1) {
      if (
        Math.abs(
          sectionPositionArray[startIndex].headerRef.current.offsetTop -
            currentPosition
        ) <
        Math.abs(
          sectionPositionArray[endIndex].headerRef.current.offsetTop -
            currentPosition
        )
      )
        return startIndex;
      else return endIndex;
    } else {
      var nextNearest = ~~((startIndex + endIndex) / 2);
      var a = Math.abs(
        sectionPositionArray[nextNearest].headerRef.current.offsetTop -
          currentPosition
      );
      var b = Math.abs(
        sectionPositionArray[nextNearest + 1].headerRef.current.offsetTop -
          currentPosition
      );
      if (a < b) {
        return nearestIndex(
          currentPosition,
          sectionPositionArray,
          startIndex,
          nextNearest
        );
      } else {
        return nearestIndex(
          currentPosition,
          sectionPositionArray,
          nextNearest,
          endIndex
        );
      }
    }
  };

  const handleNavLinkClick = (e) => {
    e.preventDefault(); // Prevent the default behavior of the link

    const targetId = e.target.getAttribute("href").slice(1);
    const targetElement = document.getElementById(targetId);
    console.log(targetElement);
    if (targetElement) {
      const navbarHeight = document.querySelector(".navbar").offsetHeight;
      const targetOffsetTop = targetElement.offsetTop - navbarHeight - 20;
      console.log(targetOffsetTop);
      window.scrollTo({
        top: targetOffsetTop,
        behavior: "smooth", // Optional: Add smooth scrolling effect
      });
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const [activeLink, setActiveLink] = useState();
  useEffect(() => {
    // console.log(window.screenY);
    // console.log(navHeader);
    const handleScroll = (e) => {
      var index = nearestIndex(
        window.scrollY,
        navHeader,
        0,
        navHeader.length - 1
      );
      setActiveIndex(index);
      setActiveLink(navHeader[index].headerTitle);
    };
    console.log(activeLink);
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <div className={`navbar ${isOpen ? "open" : ""}`}>
      <div className="menu-icon" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <ul>
        <li>
          <a
            onClick={handleNavLinkClick}
            className={activeLink === "Home" ? "active" : ""}
            href="#home"
          >
            Home
          </a>
        </li>
        <li>
          <a
            onClick={handleNavLinkClick}
            className={activeLink === "Achievements" ? "active" : ""}
            href="#achievements"
          >
            Achievements
          </a>
        </li>
        <li>
          <a
            onClick={handleNavLinkClick}
            className={activeLink === "Experience" ? "active" : ""}
            href="#experience"
          >
            Experience
          </a>
        </li>
        <li>
          <a
            onClick={handleNavLinkClick}
            className={activeLink === "Skills" ? "active" : ""}
            href="#skills"
          >
            Skills
          </a>
        </li>
        <li>
          <a
            onClick={handleNavLinkClick}
            className={activeLink === "About Me" ? "active" : ""}
            href="#aboutMe"
          >
            About Me
          </a>
        </li>
      </ul>
    </div>
  );
}
NavBar.propTypes = {
  navHeader: PropTypes.arrayOf(
    PropTypes.shape({
      headerID: PropTypes.string,
      headerRef: PropTypes.object.isRequired,
      headerTitle: PropTypes.string.isRequired,
    })
  ).isRequired,
};
export default NavBar;
